import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import * as PropTypes from 'prop-types';
import Div100vh from 'react-div-100vh';
import { Grid, Typography } from '@material-ui/core';

import Button from '$landing-page/components/button';
import PageMetadata from '$landing-page/components/page-metadata';

import useStyles from './styles';

const query = graphql`
    query fullLayout {
        pattern: file(relativePath: { eq: "page-error-pattern.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, width: 1920, quality: 100, placeholder: NONE)
            }
        }
    }
`;

const FullLayout = (props) => {
    const { pathname, image, title, description, showContactUs } = props;

    const queryData = useStaticQuery(query);
    const classes = useStyles();

    return (
        <Div100vh>
            <Grid container direction="row" className={classes.root}>
                <Grid item style={{ flexGrow: 1 }} className={classes.root}>
                    <BackgroundImage
                        item
                        Tag="div"
                        {...convertToBgImage(getImage(queryData.pattern.childImageSharp.gatsbyImageData))}
                        className={classes.backgroundImage}
                    >
                        <PageMetadata {...{ title, description, pathname }} />
                        <GatsbyImage image={getImage(image)} className={classes.image} alt={title} />
                        <Typography variant="h1" className={classes.title}>
                            {title}
                        </Typography>
                        <Typography variant="h2" className={classes.description} color="textSecondary">
                            {description}
                        </Typography>
                        <Button component={Link} to="/" variant="contained" className={classes.button}>
                            Go to home page
                        </Button>
                        <br />
                        {showContactUs && (
                            <Typography
                                variant="h6"
                                component="a"
                                href="mailto:support@ethicadata.com"
                                className={classes.contactUs}
                            >
                                Contact Us
                            </Typography>
                        )}
                    </BackgroundImage>
                </Grid>
            </Grid>
        </Div100vh>
    );
};

FullLayout.defaultProps = {
    showContactUs: true,
};

FullLayout.propTypes = {
    pathname: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    showContactUs: PropTypes.bool,
    image: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default FullLayout;
